// Set up multi-submit protection on forms
document.addEventListener("DOMContentLoaded", () => {
  const forms = document.querySelectorAll("form");
  forms.forEach((form) => {
    form.addEventListener("submit", (event) => {
      let alreadySubmitted = false;

      const buttons = form.querySelectorAll("input[type=\"submit\"]");
      buttons.forEach((button) => {
        if (button.classList.contains("submitted")) {
          alreadySubmitted = true;
        } else {
          button.classList.add("submitted");

          // Re-enable the button after a few seconds to avoid people trying to refresh the page because
          // the button doesn't work (e.g. after hitting "back" on the browser and loading the cached page)
          // See: https://www.notion.so/Email-missing-from-session-during-signup-13c04abee13880619bfce53639c4fa7e
          setTimeout(() => {
            button.classList.remove("submitted");
          }, 3000);
        }
      });

      if (alreadySubmitted) {
        event.preventDefault();
      }
    });
  });
});
